/* ----------------------------------------------
 * Generated by Animista on 2021-5-25 11:21:13
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-bottom {
    0% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }

  @-webkit-keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-bottom {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(1000px);
              transform: translateY(1000px);
      opacity: 0;
    }
  }

  .slide-out-bottom {
	-webkit-animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-bottom 0.5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}

  .slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s ;
	        animation: slide-in-bottom 0.5s ;
}

.course-info {
    padding-top: 20px;
    color: white;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.4;
    font-size: 10pt;
    font-weight: 500;
}

.course-logo {
    height: 22px;
    width: auto;
    position: absolute;
    top: 4px;
    left: 12px;
    padding-top: 10px;
    padding-bottom: 10px;
 
}

.course-main {
    width: 100%;
    padding: 0;
    margin: 0 auto;
    background-color: rgb(0, 0, 0);
    overflow: hidden;
    padding-bottom: 200px;
}

.fifty {
    display: inline-block;
    margin: 0 auto;
    width: 47%;
    height: 200px;
    text-align: center;
    color: white;
}

.course-section {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    padding: 0px;
    width: 92%;
    height: 90%;
    border-radius: 20px;
    opacity: 1;
    background-color: rgb(0, 0, 0);
    box-shadow: rgba(88, 57, 102, 0.2) 0px 2px 8px 0px;

}

.course-back-image {
    width: auto;
    height: 120%;
    position: relative;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: 0;
    opacity: .6;
    z-index: 0;
    border-radius: 20px;
}

.course-section:after {
    position: absolute;
    pointer-events: none;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 20px;
    box-shadow: inset 0 0 0 1px rgba(255, 255, 255, .2);
    background-image: linear-gradient(-45deg, rgba(24, 23, 23, 0.514), rgba(255, 255, 255, 0.308) 50%, rgba(255, 255, 255, 0.082) 50%);
    -webkit-mask-image: linear-gradient(#000, transparent);
}


.course-section-title {
    position: absolute;
    z-index: 100;
    text-transform: uppercase;
    text-align: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11pt;
    font-weight: 900;
    opacity: 1;
    color: #ffffff;
    font-family: 'Roboto Condensed', sans-serif;

}

.p-20 {
    padding-bottom: 20px;
    padding-top: 10px;
    font-size: 18pt;
    font-weight: 350;
    background-image: radial-gradient(to center, white, black);
    -webkit-background-clip: text;
    text-align: left;
}

.p-10 {
  padding: 20px;
  padding-top: 0;
  padding-bottom: 10px;
  line-height: 1.4em;
  margin-bottom: 20px;
}

.open-section {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 100000;
    top: 7%;
    background-color: #f0f0f0;
    color: black;
    height: 90%;
    font-size: 11pt;
    font-weight: 300;
    overflow-y: scroll;
    padding-bottom: 100px;
    transition: .6s;
}

.hidden-down {
        position: fixed;
        width: 100%;
        left: 0;
        z-index: 100000;
        top: 100vh;
        background-color: #f0f0f0;
        color: black;
        height: 90%;
        font-size: 11pt;
        font-weight: 300;
        overflow-y: scroll;
        padding-bottom: 100px;
        transition: .6s;
}

.full-overlay {
    position: absolute;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: .6s ease-in-out;
    opacity: .8;
    background-color: rgba(228, 227, 227, 0.288);
}

.full-overlay i{
    position: fixed;
    top: 2.5%;
    font-size: 16pt;
    z-index: 99999999999999;
    right: 10px;
    opacity: 1;
    color: black;
    background: transparent;
    transition: .6s ease-in-out;


}

.text-box {
    padding-top: 20px;
    width: 90%;
    line-height: 1.6em;
    font-size: 11.5pt;
    font-weight: 400;
    margin: 0 auto;
    text-align: left;
    padding-bottom: 100px;
}

img.inside-text-box {
    padding-top: 5px;
    padding-bottom: 5px;
}

video.inside-text-box {
    width: 100%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

h1.crse-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 24pt;
    font-weight: 600;
    padding: 10px;
    padding-top: 20px;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 1px solid #e72f60;
}

p.prelude {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 20px;
    font-style: italic;
}

h1.crse {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 16pt;
    font-weight: 300;
    padding: 10px;
    text-transform: uppercase;
    padding-bottom: 20px;
}

p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 20px;
}


p.closing {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(0, 0, 0, 0.788);
    padding: 10px;
    padding-bottom: 0;
    font-weight: 700;
}

h2.crse {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 14pt;
    text-transform: uppercase;
    font-weight: 300;
    color: black;
    padding: 10px;
}

.bloodborne p {
    color: red;
}

.course-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 990;
  left: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background: black;
  color: white;
  text-align: center;
  border-top: 1px solid #221f21;
}

.mobile-navbar-container {
  border-top: none;
}

.mobile-navbar-link {
  display: inline-block;
  position: relative;
  font-size: 8.5pt;
  font-weight: 280;
  color: #8a7a86;
  vertical-align: top;
  margin: 0 auto;
  width: 25%;
  opacity: 1;
  text-decoration: none;
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.mobile-navbar-link-purp {
  display: inline-block;
  position: relative;
  font-size: 8.5pt;
  font-weight: 400;
  color: #e72f60;
  color: white;
  vertical-align: top;
  margin: 0 auto;
  width: 25%;
  opacity: 1;
  text-decoration: none;
  text-align: center;
  background: linear-gradient(306deg, #7b4397, #e72f60);

  background: black;
  background-size: 400% 400%;
  -webkit-animation: WelcomePageGradient 4s ease infinite;
  -moz-animation: WelcomePageGradient 4s ease infinite;
  animation: WelcomePageGradient 4s ease infinite;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}



.mobile-navbar-link-purp i {
  font-size: 15pt;
  font-weight: 400;
  padding-bottom: 4px;
}

.assignment-link {
  color: #e72f60;
}

.mobile-navbar-link i {
  font-size: 15pt;
  padding-bottom: 4px;
}

i {
  font-size: inherit;
}

.studio-entry-row-title {
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 10.5pt;
  font-weight: 400;
  padding: 0;
  padding-left: 5px;
  padding-bottom: 0px;
  background-color: transparent;
  
}

.assignment-submission {
  border-bottom: 1px solid rgba(255, 255, 255, 0.329);
}

.back-to-home {
  text-transform: uppercase;
  font-size: 10pt;
  font-weight: 550;
  padding-top: 20px;
  padding-left: 20px;
}

.pad-20-t {
  padding-top: 20px;
}
.wht-back {
  color: black;
  background-color: #f7f7f7;
  padding-bottom: 70px;
  overflow-y: scroll;

}

.white-back {
  background-color: white;
  width: 100%;
  padding: 0;
  margin: 0;
  margin-top: 10px;
}

.pad-10 {
  padding: 10px;
  padding-top: 20px;
}

.pad-20 {
  padding: 20px;
}

.font {
  font-size: 10pt;
  color: #939393;
  padding-top: 0px;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.pad-20 i {
  padding-top: 20px;
}

.welcome {
  position: absolute;
  top: 42%;
  transform: translate(0, -50%);
  background-color: black;
}

.profile {
  position: absolute;
  z-index: 10000000000000000;
  top: 52px;
  right: 0;
  width: 30%;
  text-align: left;
  padding: 20px;
  padding-top: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12pt;
  background-color: white;
  color: black;
}

.profile-p {
  position: absolute;
  z-index: 10000000000000000;
  top: 52px;
  right: 0;
  width: 30%;
  text-align: left;
  padding: 20px;
  padding-top: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 12pt;
  background-color: #f7f7f7;
  color: black;
}

.lower-case {
  text-transform: capitalize;
  padding-bottom: 10px;
  margin-bottom: 0;
}

i { font-size: inherit;}

h1 {
  font-weight: 300;
  font-size: 28px;
  text-align: left;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  text-transform: uppercase;
}

h1.assignments {
  font-weight: 300;
  padding-bottom: 0;
}

.course-logo-c {
  left: 50%;
  transform: translate(-50%);
  height: 37px;
  padding: 20px;
  width: auto;
  position: absolute;

}

.no-pad {
  padding: 0;
  padding-top: 30px;
  padding-bottom: 20px;
}

.size-up {
  font-size: 42px;
}

@media (min-width: 800px) {
  .PMU-details {
    width: 100;
    margin: 0 auto;
  }

  .pmu-details-welcome {
    width: 70%;
    margin: 0 auto;
    text-align: center;
  }

  .welcome {
    width: 100%;
    margin: 0 auto;
    left: 50%;
    top: 23%;
    transform: translate(-50%);
  }

  .drop {
  }

  span.add {
    position: absolute;
    right: 30px;
    color: rgb(43, 43, 43);
    font-size: 9pt;
    background-color: #f7f7f7;
}

.p700 {
  width: 700px;
  margin: 0 auto;
}

.fifty {
  display: inline-block;
  margin: 0 auto;
  width: 25%;
  height: 200px;
  text-align: center;
  color: white;
}

.course-section {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 auto;
  padding: 0px;
  width: 94%;
  height: 80%;
  border-radius: 20px;
  opacity: 1;
  background-color: rgb(0, 0, 0);
  box-shadow: rgba(88, 57, 102, 0.2) 0px 2px 8px 0px;

}

.course-section:hover {
  cursor: pointer;
}

.p600 {
  width: 600px;
  margin: 0 auto;
}

.purp {
  color: #e72f60;
  background-color: #e72f60;
}

}

.p {
  font-size: 10pt;
}