img.logo-lay2 {
    position: absolute;
    top: 40%; left: 50%; transform: translate(-50%, -50%);
    z-index: -1;
    width: 40%;
    opacity: .8;
}

tr {
    
}

tbody {
    height: fit-content;
}

.right-side {
    height: 90vh;
    overflow: hidden;
    overflow: scroll;
}


.each-artist {
    width: 210px;
    margin: 2px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    text-align: center;
    background: #191919;
    background-color: #272727;
    background-color: #221f21;
    height: 1600px;
    margin-bottom: 200px;
    
}

.appointment-box3.box-90 {
    height: 45px;
    overflow: hidden;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-90 {
    height: 180px;
    overflow: scroll;
}

.appointment-box3.box-180 {
    height: 90px;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-180 {
    height: 390px;
    overflow: scroll;
}

.appointment-box3.box-210 {
    height: 105px;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-210 {
    height: 420px;
    overflow: scroll;
}

.appointment-box3.box-240 {
    height: 120px;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-240 {
    height: 480px;
    overflow: scroll;
}

.appointment-box3.box-360 {
    height: 180px;
    overflow: scroll;
    outline: .5px solid black;
}

.appointment-box.box-360 {
    height: 720px;
    overflow: scroll;
}



.artist-col-name {
    color: white;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 12px;
    border-bottom: 2px solid #111011;;
}



img.logo-lay {
 position: absolute;
 top: 40%; left: 50%; transform: translate(-50%, -50%);

 width: 40%;
 z-index: 9999999;
 background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
 background-size: 200% 280%;

 -webkit-animation: WelcomePageGradient2 .8s ease infinite;
 -moz-animation: WelcomePageGradient2 .8s ease infinite;
 animation: WelcomePageGradient2 .8s ease infinite;
 opacity: .8;

}

.appointment-box {
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 30px;
    text-align: left;
    background-color:  #140512;
    background-color: #111011;
    padding: 10px;
    padding-bottom: 15px;
    white-space: normal;
    color: rgba(255, 255, 255, 0.951);
}

.appointment-box2 {
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: left;
    background-color:  #140512;
    background-color: #111011;
    padding: 10px;
    padding-bottom: 12px;
    white-space: normal;
    color: rgba(255, 255, 255, 0.951);
    overflow: scroll;
    position: relative;
    display: block;
    font-size: 8pt;

}

.appointment-box3 {
    margin: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
    text-align: left;
    background-color:  #140512;
    background-color: #111011;
    padding: 10px;
    padding-bottom: 12px;
    white-space: normal;
    color: rgba(255, 255, 255, 0.951);
    overflow: scroll;
    position: relative;
    display: block;
    font-size: 8pt;
}

.appointment2-1000am {
    display: block;
    position: absolute;
    top: 10px;
}

.appointment-1000am {
    position: absolute;
    top: 45px;
}

.appointment2-1100am {
    position: absolute;
    top: 40px;
}

.appointment-1100am {
    position: absolute;
    top: 200px;
}

.appointment2-1130am {
    position: absolute;
    top: 55px;
}

.appointment-1130am {
    position: absolute;
    top: 255px;
}

.appointment2-100pm {
    position: absolute;
    top: 100px;
}


.appointment-100pm {
    position: absolute;
    top: 465px;
}

.appointment2-130pm {
    position: absolute;
    top: 115px;
}

.appointment-130pm {
    position: absolute;
    top: 480px;
}

.appointment2-200pm {
    position: absolute;
    top: 130px;
}

.appointment-200pm {
    position: absolute;
    top: 560px;
}

.appointment2-230pm {
    position: absolute;
    top: 145px;
}

.appointment-230pm {
    position: absolute;
    top: 675px;
}

.appointment2-300pm {
    position: absolute;
    top: 160px;
}

.appointment-300pm {
    position: absolute;
    top: 7755px;
}

.appointment2-400pm {
    position: absolute;
    top: 190px;
}

.appointment-400pm {
    position: absolute;
    top: 885px;
}

.appointment-430pm {
    position: absolute;
    top: 940px;
}

.appointment2-500pm {
    position: absolute;
    top: 220px;
}

.appointment-500pm {
    position: absolute;
    top: 1005px;
}



.appointment2-530pm {
    position: absolute;
    top: 235px;
}


.appointment-530pm {
    position: absolute;
    top: 1095px;
}

.appointment-box3.lips {
    background:  #e72f60;
}

.appointment-box.lips {
    border: .5px solid #e72f60;
}

.appointment-box3.brows {
    background: #7b4397;
    
}

.appointment-box.brows {
    border: .5px solid #7b4397;
    
}

.appointment-box3.other {
    background:  #c5b206f5;
}

.appointment-box.other {
    border: .5px solid #c5b206f5;
}

.appointment-box.students {
    border: .5px solid #1c2ba2;
}

.appointment-box3.eyeliner {
    border: .5px solid white;
}

.appointment-box.eyeliner {
    border: .5px solid white;
}

.appointment-box3.package {
    border-bottom: .5px solid #e72f60;
    border-left: .5px solid #7b4397;
    border-right: .5px solid #e72f60;
    border-top: .5px solid #7b4397
}

.appointment-box.package {
    border-bottom: .5px solid #e72f60;
    border-left: .5px solid #7b4397;
    border-right: .5px solid #e72f60;
    border-top: .5px solid #7b4397
}



.appointment-box::after {
    width: 92%;
    background-clip: border-box;
    background: linear-gradient(306deg, #7b4397, #e72f60);
    background-size: 400% 400%;
    background-clip: border-box;
    color: transparent;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    -moz-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
}



.today-time {
    font-weight: 700;
    color: rgb(240, 240, 240);
    padding-top: 7px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-size: 10pt;
    display: inline-block;
}

.today-type {
    padding-top: 5px; padding-bottom: 5px;
    color: rgba(156, 156, 156, 0.89);
    text-transform: uppercase;
    font-size: 10pt;
    margin-top: 0px;
}

.today-type2 {
    padding-top: 5px; padding-bottom: 5px;
    color: rgba(156, 156, 156, 0.89);
    text-transform: uppercase;
    font-size: 9pt;
    margin-top: 0px;
}

.today-due {
    display: block;
    margin-top: 7px;
    margin-bottom: 0px;
    color: rgba(255, 255, 255, 0.89);
    text-transform: uppercase;
    font-size: 9pt;
    background-color: #272727;
    border: 1px solid transparent;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    width: fit-content;
    border-radius: 20px;
}

.today-name {
    padding-top: 5px; padding-bottom: 5px;
    color: rgba(255, 255, 255, 0.89);
    text-transform: uppercase;
    font-size: 10pt;
}

.today-notes {
    color: rgb(102, 102, 102);
    font-style: italic;
    font-size: 10pt;
    padding-top: 5px; padding-bottom: 5px;
}

.scroll {
    overflow-y: scroll;
}

.day-box {
    text-align: left;
    display: inline-block;
    background-color: #313131;
    width: 30px;
    height: 30px;
    font-size: 9pt;
    text-align: left;
    padding: 10px;
    border-radius: 2px;
    margin: 2px;
}

.abs-s {
    position: absolute;
    top: 40%; left: 50%; transform: translate(-50%, -50%);
    margin: 0 auto;
    text-align: center;
}

textarea.notes-input {
    margin-top: 10px;
    width: 300px;
    padding: 10px;
}

.abs-cal {
    position: absolute;
    margin: 0 auto;
    text-align: center;
    width: fit-content;
    top: 30%;
    left: 50%; transform: translate(-50%, -50%);
}

.main-container {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
}

.calendar-container {
    margin: 0 auto;
    width: 90%;
}



.calendar-day {

 
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-size: 9pt;
    padding: 5px;
    padding-bottom: 6px;
    padding-top: 4px;
    margin: 2px;
    text-align: center;

    transition: .3s;
    vertical-align: middle;
}

.dot.calendar-day {
    background-color: #221f21;
}

.no-dot td.calendar-day {
    background-color: transparent;
}

.calendar-day2 {
    background-color: #3d3d3d;
    background-color: #221f21;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    font-size: 9pt;
    padding: 5px;
    padding-bottom: 6px;
    padding-top: 4px;
    margin: 2px;
    text-align: center;
    transition: .3s;
    vertical-align: middle;
    position: relative;
}
.today.calendar-day2 {
    background-color: #7b4397;

}

.no-dot td.calendar-day2 {
    background-color: transparent;
}

.calendar-day3 {
    background-color: #3d3d3d;
    background-color: #221f21;
    width: 200px;
    height: 330px;
    border-radius: 3px;
    font-size: 9pt;
    padding: 5px;
    padding-bottom: 6px;
    padding-top: 4px;
    margin: 2px;
    text-align: left;
    transition: .3s;
    vertical-align: top;
    position: relative;
}

.calendar-table {
    margin: 0 auto;

}

td {
    text-align: center;
    padding: 3px;
    margin: 0 auto;
    width: fit-content;
}

td:hover {
    cursor: pointer;
}

.calendar-month.today, .calendar-month-2.today {
    color: white;
}

td.today {
    color: black;
    background-color: white;
    transition: .2s;
    background: linear-gradient(306deg, #7b4397, #e72f60);

    background: black;
    border: 2px solid #ffffff;
    border-radius: 50px;
    background-size: 400% 400%;
    color: white;
    color: rgba(255, 255, 255, 0.89);
    padding: 0px;
    padding-left: 0px;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    -moz-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
}

td.today.dot {
    color: white;
    background-color: #7b4397;
}


td.today i {
    color: white;
    background-color: black;
    padding: 5px;
}

.abs-nav {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 999999999999999999;
}

.abs-nav:hover {
    cursor: pointer;
}


.full-100 {
    height: fit-content;
    
    padding-bottom: 100px;
 
 
}

.scheduled-appt-title {
    font-weight: 700;
    text-transform: uppercase;
    padding: 20px;
    padding-top: 10px;
    font-size: 13px;
    margin: 0 auto;
    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.scheduled-appt-title:hover {
    cursor: pointer;
}

.moving-gradient {
    background: linear-gradient(306deg, #7b4397, #e72f60);
    background-size: 400% 400%;
    background-clip: text;
    color: transparent;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    -moz-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
}

.bottom-half {
    position: relative;
    background-color: #221f21;
    padding-bottom: 10px;
    padding-top: 20px;
    min-height: inherit;
    min-height: 100vh;
}

.name-title {
    font-size: 14pt;
}

.year-list {
    width: fit-content;
    margin: 0 auto;
    text-align: center;
}

.inline {
    display: inline-block;
    padding: 10px;
    padding-bottom: 0;
    background: black;
}

.inline:hover {
    cursor: pointer;
}

td.calendar-month {
    display: block;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 6px;
    width: fit-content;
    margin: 0 auto;
    position: relative;
}

td.calendar-month-2 {
    display: block;
    padding: 10px;
    padding-top: 7px;
    padding-bottom: 6px;
    width: fit-content;
    position: relative;
    margin: 0 auto;
} 
tr.cal-month-list {
    position: relative;
    display: block;
    z-index: 9999;
}

.abs-list {
    position: absolute;
    right: 30%;
    background: black;
    border: none;
    z-index: 100;
}

.abs-list-2 {
    position: absolute;
    background: black;
    border: none;
    right: 5%;
    z-index: 100;
}

.abs-list-3 {
    position: relative;
    background: black;
    padding-bottom: 0px;
    top: 60%;
    left: 50%;
    width: 400px;
    transform: translate(-50%, -50%);
    z-index: 999;
    transition: .2s;

}

.medical-form-overall {
    color: red;
    font-size: 9.5pt;
    text-transform: uppercase;
    padding-top: 5px;
    padding-bottom: 5px;
}

.abs-list-4 {
    position: relative;
    z-index: 999;
    transition: .2s;
    left: 50%; transform: translate(-50%);
  
}

.full-blur {
    position: fixed;
    top: 0; left: 0; right: 0; bottom: 0;
    width: 100%;
    height: 100vh;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.856);
    transition: .2s;
    backdrop-filter: blur(7px);
}

.w-100 {
    width: 100%;
    text-align: left;
    padding-left: 0px;
    padding-top: 0;
}

tr.available {
    background-color: red;
}

.not-available {
    color: #7e7e7e;
    transition: .2s;
}

.available {
    background-color: #666666;
    transition: .2s;
}

.tudate {
    background-color: #e72f60;
    color: black;
    transition: .2s;
}

.gry {
    color: #999999;
}

.yellow {
    color: #e72f60;
}

.booking-link {
    padding: 12px;
    padding-left: 15px; padding-right: 15px;
    color: white;
    background-color: #e72f60;
    text-align: center;
    background: linear-gradient(306deg, #7b4397, #e72f60);
    background-size: 400% 400%;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    -moz-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
    text-transform: uppercase;
    font-size: 9pt;
    font-weight: 700;
    margin: 20px;
}

.booking-link:hover {
    cursor: pointer;
}

.booking-link-2 {
    padding: 12px;
    padding-left: 15px; padding-right: 15px;
    color: transparent;
    text-align: center;
    background: transparent;
    background-size: 400% 400%;
    -webkit-animation: WelcomePageGradient 4s ease infinite;
    -moz-animation: WelcomePageGradient 4s ease infinite;
    animation: WelcomePageGradient 4s ease infinite;
    text-transform: uppercase;
    font-size: 9pt;
    font-weight: 700;
    margin: 20px;
}

.touch-up-link {
    display: inline-block;
    background-color: #313131;
    width: fit-content;
    padding: 5px;
    padding-left: 10px; padding-right: 10px;
    border-radius: 20px;
    color: #bbbbbb;
    text-transform: uppercase;
    font-size: 8pt;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 5px;
    margin-right: 8px;
}

.booking-page-full {
    position: fixed;
    top: 0px;
    padding-top: 60px;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    z-index: 999999999;
    background-color: rgba(0, 0, 0, 0.712);
    backdrop-filter: blur(7px);
    z-index: 99999999999999999999999;

}

.booking-page-inside {
    position: relative;
    height: 90vh;
    overflow: scroll;
    padding-bottom: 100px;
}

.logo-layer-1 {
    position: absolute;
    top: 10%; left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 62%;
    height: auto;
}

.logo-layer-2 {
    position: absolute;
    top: 10%; left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
    background-size: 200% 180%;
  
    -webkit-animation: WelcomePageGradient2 6s ease ;
    -moz-animation: WelcomePageGradient2 6s ease ;
    animation: WelcomePageGradient2 6s ease ;
    z-index: 9;
    width: 62%;
    height: auto;
}

.logo-layer-2b {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
    background-size: 180% 180%;
  
    -webkit-animation: WelcomePageGradient 6s ease infinite;
    -moz-animation: WelcomePageGradient 6s ease infinite;
    animation: WelcomePageGradient 6s ease infinite;
    z-index: 99999;
    width: 92%;
    height: auto;
}

.logo-flash {
    width: 50%;
    height: auto;

}

.btnlogin {
    position: absolute;
    top: 35.5%; transform: translate(0, -50%);
    z-index: 99999999;
}

.front-layer {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: (-50%, -50%);
    z-index: 9999999999999999999;
}

.pnk-wrap {
    border: 2px solid #e72f60;
}

.login-box {
    width: 60%;
    margin: 0 auto;
}

td:hover {
    cursor: pointer;
}

.task-list {
    background: black;
     padding-top: 0px;

     border-top: .5px solid white;
 }

 .task-title {
    font-size: 14pt;
 }
 
 .fxd {
     position: sticky;
     top: 0;
     padding-top: 15px;
     z-index: 99;
     width: 90%;
     background: black;
     border-bottom: .5px solid white;
  
 }
 
 .inner-task-list {
     padding-bottom: 50px;
     width: 100%;
     white-space: normal;
 }
 
 .task-box {
     position: relative;
     height: fit-content;
     background: #221f21;
     margin-top: 10px;
     color: white;
     font-size: 10pt;
     width: 90%;
     margin: 10px auto;
     padding: 10px;
     text-align: left;
    
 }
 
 
 .add-btn {
     padding: 18px;   
     background: #7b4397;
     border-radius: 50px;
     position: absolute;
     right: 10px;
     top: 6px;
     color: white;
     font-size: 13pt;
     text-align: center;
     vertical-align: middle;
     position: absolute;
 }
 
 .inner-add-btn {
     position: absolute;
     top: 45%; left: 50%;
     transform: translate(-50%, -50%);
     text-align: center;
 }
 
 button:hover {
     cursor: pointer;
 }
 

 .create-task {
    position: relative;
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 20px;
    text-align: center;
 }
 .add-task-box {
     position: relative;

     margin: 0 auto;

 }
 
 .task-btn-row {
     text-align: right;
     margin-top: 10px;
     margin-bottom: 0px;
 }
 
 .task-btn {
     background: #121212;
     border-radius: 50px;
     color: white;
     padding: 8px;
     padding-left: 10px;
     padding-right: 10px;
     margin-right: 0px;
 }
 
 .task-btn i {
     font-size: 11pt;
 }
 
 .task-btn:hover {
     cursor: pointer;
 }

 .submitted-by {
    display: inline-block;
    padding-top: 7px;
    padding-right: 7px;
    color: #7b7b7b;
    color: #63506c;
    font-size: 12px;
    padding-bottom: 10px;
 }

 .submitted-by.date {
    display: inline-block;
    font-style: italic;
    color: #4f4f4f;
    color: #63506c;
    color: #7b7b7b;
    font-size: 12px;
 }

 .submitted-by-body {
    padding-top: 7px;
 }
 

@media (max-width: 800px) {

    .booking-page-full {
        position: absolute;
        top: 0px;
        padding-top: 60px;
        width: 100%;
        min-height: 100vh;
        z-index: 999999999;
        background-color: rgba(0, 0, 0, 0.712);
        backdrop-filter: blur(7px);
    }

    .login-box {
        width: 80%;
        margin: 0 auto;
    }

    .logo-flash {
        width: 100%;
        height: auto;
    }

        .logo-layer-1 {
            position: absolute;
            top: 10%; left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
            width: 92%;
            height: auto;
        }
        
        .logo-layer-2 {
            position: absolute;
            top: 10%; left: 50%;
            transform: translate(-50%, -50%);
            background: linear-gradient(306deg, black, black, black, black, transparent, transparent, transparent);
            background-size: 200% 180%;
          
            -webkit-animation: WelcomePageGradient2 4s ease ;
            -moz-animation: WelcomePageGradient2 4s ease ;
            animation: WelcomePageGradient2 4s ease ;
            z-index: 9;
            width: 92%;
            height: auto;
        }

}
